<template>
  <div>
    <div
      class="
        nk-add-product
        toggle-slide toggle-slide-right toggle-screen-any
        content-active
      "
    >
      <div class="simplebar-wrapper" style="margin: -24px">
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer"></div>
        </div>
        <div class="simplebar-mask">
          <div class="simplebar-offset" style="right: 0px; bottom: 0px">
            <div
              class="simplebar-content-wrapper"
              style="height: 100%; overflow: hidden scroll"
            >
              <div class="simplebar-content" style="padding: 24px">
                <div class="nk-block-head">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title">Add Product</h5>
                    <div class="nk-block-des">
                      <p>Add a new product</p>
                    </div>
                  </div>
                </div>
                <div class="nk-block">
                  <div class="row g-3">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="form-label" for="product-title"
                          >Title</label
                        >
                        <div class="form-control-wrap">
                          <input
                            type="text"
                            class="form-control"
                            id="product-title"
                            v-model="newProduct.title"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label class="form-label" for="category"
                          >Product category</label
                        >
                        <div class="form-control-wrap">
                          <input
                            type="text"
                            class="form-control"
                            id="category"
                            placeholder="Category"
                            v-model="newProduct.category"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label class="form-label" for="price">Price</label>
                        <div class="form-control-wrap">
                          <input
                            type="text"
                            class="form-control"
                            id="price"
                            placeholder="Price"
                            v-model="newProduct.price"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label class="form-label" for="Comment"
                          >Description</label
                        >
                        <div class="form-control-wrap">
                          <textarea
                            type="text"
                            class="form-control"
                            rows="2"
                            style="height: auto !important"
                            id="Comment"
                            v-model="newProduct.description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h6>Images</h6>
                      <hview-upload
                        :input-id="'product-gallery'"
                        :extentions="['png', 'jpg', 'jpeg', 'gif']"
                        @after-uploading="handleUploadedGalleryItem"
                        :post-data="{
                          uploadPath: $store.state.projectMediaPath,
                        }"
                      ></hview-upload>
                      <template v-if="newProduct.gallery.length">
                        <div class="row border-bottom py-2 align-items-center">
                          <div
                            class="col-lg-3"
                            v-for="(tempImg, key) in newProduct.gallery"
                            :key="'gallery' + key"
                          >
                            <div class="d-block align-items-center">
                              <div class="p-1">
                                <img
                                  :src="apiURL + '/' + tempImg"
                                  class="img img-thumbnail rounded img-fluid"
                                />
                              </div>
                              <div class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  @click.prevent="
                                    handleGalleryReset(key, tempImg)
                                  "
                                  class="btn btn-sm btn-outline-danger p-0"
                                  style="font-size: 10px; border: none"
                                >
                                  <span class="icon ni ni-cross"></span> Remove
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-right mt-2">
                          <button
                            class="btn btn-secondary btn-sm"
                            type="button"
                            @click="showFilePicker('product-gallery')"
                          >
                            Add New
                          </button>
                        </div>
                      </template>
                      <div
                        class="jb-gallery-upload"
                        v-else
                        @click="showFilePicker('product-gallery')"
                      >
                        <p class="text-center mb-0">
                          <img
                            :src="`${publicPath}img/upload-icon.png`"
                            class="img img-fluid"
                          />
                        </p>
                        <p class="text-mutted">
                          No images available in the gallery now
                        </p>
                      </div>
                    </div>
                    <div class="col-12">
                      <wolf-button
                        caption="Save"
                        @btn-role="handleSubmit"
                        :disabler="
                          !newProduct.title || newProduct.gallery.length == 0
                        "
                        activator="saving_info"
                      ></wolf-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="simplebar-placeholder"
          style="width: auto; height: 699px"
        ></div>
      </div>
      <div
        class="simplebar-track simplebar-horizontal"
        style="visibility: hidden"
      >
        <div
          class="simplebar-scrollbar"
          style="width: 0px; display: none"
        ></div>
      </div>
      <div
        class="simplebar-track simplebar-vertical"
        style="visibility: visible"
      >
        <div
          class="simplebar-scrollbar"
          style="
            height: 36px;
            transform: translate3d(0px, 0px, 0px);
            display: block;
          "
        ></div>
      </div>
    </div>
    <div class="toggle-overlay" @click.prevent="$emit('close')"></div>
  </div>
</template>
<script>
export default {
  name: "ProductCreator",
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    newProduct: {
      title: null,
      category: null,
      description: null,
      price: null,
      gallery: [],
    },
  }),
  created() {
    if (!this.empty(this.product)) {
      this.newProduct = { ...this.product };
    }
  },
  methods: {
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.newProduct.image = this.newProduct.gallery[0];
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.newProduct),
              url: "admin/products/add",
            })
            .then((response) => {
              if (response.data.status) {
                this.$emit("saved", response.data.products);
                this.$emit("close");
              }
            });
        }
      });
    },
    handleUploadedGalleryItem(file) {
      this.newProduct.gallery.push(file.file_path);
    },
    handleGalleryReset(key, filename) {
      if (confirm("Are you want to remove this image from Gallery?")) {
        this.$store
          .dispatch("postRequest", {
            formData: this.formData({
              uploadPath: this.$store.state.projectMediaPath,
            }),
            url: "media/delete-hview-file/" + filename,
          })
          .then(() => {
            if (this.newProduct.gallery.includes(filename)) {
              this.newProduct.gallery.splice(key, 1);
            }
          });
      }
    },
  },
};
</script>
