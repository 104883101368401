<template>
  <div class="container-fluid">
    <hview-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head">
        {{ newMember.id ? "Update team member" : "Add a new team membeer" }}
      </span>
      <div slot="body">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mb-2">
              <label class="mb-0">Name</label>
              <input
                type="text"
                v-model="newMember.name"
                name="Full Name"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('Full Name') }"
                class="form-control form-control-lg"
                placeholder="Full Name"
              />
              <div class="invalid-feedback">
                {{ errors.first("Full Name") }}
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-2">
              <label class="mb-0">Position / Title</label>
              <input
                type="text"
                v-model="newMember.title"
                name="Title"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('Title') }"
                class="form-control form-control-lg"
                placeholder="Title"
              />
              <span class="invalid-feedback">{{ errors.first("Title") }}</span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-2">
              <label class="mb-0">Email address</label>
              <input
                type="email"
                v-model="newMember.email"
                name="Email"
                class="form-control"
                placeholder="Email address"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-2">
              <label class="mb-0">Phone Number</label>
              <input
                type="text"
                v-model="newMember.phone"
                name="Phone"
                class="form-control form-control-lg"
                placeholder="Phone number"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-2">
              <label class="mb-0">Facebook</label>
              <input
                type="text"
                v-model="newMember.facebook_url"
                class="form-control"
                placeholder="Facebook URL"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-2">
              <label class="mb-0">Instagram</label>
              <input
                type="text"
                v-model="newMember.instagram_url"
                class="form-control"
                placeholder="Instagram URL"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-2">
              <label class="mb-0">Twitter</label>
              <input
                type="text"
                v-model="newMember.twitter_url"
                class="form-control"
                placeholder="Twitter URL"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-2">
              <label class="mb-0">LinkedIn</label>
              <input
                type="text"
                v-model="newMember.linkedin_url"
                class="form-control"
                placeholder="Linkedin URL"
              />
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group mb-2 mb-3">
              <label class="form-label">About</label>
              <textarea
                type="text"
                v-model="newMember.about"
                name="About"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('About') }"
                class="form-control"
                placeholder="Briefy Notes"
              ></textarea>
              <div class="invalid-feedback">
                {{ errors.first("About") }}
              </div>
            </div>
          </div>
        </div>
        <form>
          <div v-if="!empty(validationErrors)" class="mb-3">
            <ul class="alert alert-danger">
              <li
                v-for="(value, key, index) in validationErrors"
                :key="'error' + key + index"
              >
                {{ value }}
              </li>
            </ul>
          </div>

          <div class="mb-2">
            <div class="d-flex">
              <wolf-button
                caption="Confirm and Save"
                @btn-role="addMember"
                classes="btn btn-primary btn-lg ml-auto"
                :disabler="!newMember.name"
                activator="saving_user"
              ></wolf-button>
            </div>
          </div>
        </form>
      </div>
    </hview-modal>
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Team Members</h3>
              <div class="nk-block-des text-soft">
                <p>You have total {{ members.length }} members.</p>
              </div>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a
                  href="javascript:void(0)"
                  class="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="more-options"
                  ><em class="icon ni ni-more-v"></em
                ></a>
                <div class="toggle-expand-content" data-content="more-options">
                  <ul class="nk-block-tools g-3">
                    <li>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right">
                          <em class="icon ni ni-search"></em>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="default-04"
                          placeholder="Search by name"
                        />
                      </div>
                    </li>
                    <li>
                      <div class="dropdown">
                        <a
                          href="javascript:void(0)"
                          class="
                            dropdown-toggle dropdown-indicator
                            btn btn-outline-light btn-white
                          "
                          data-toggle="dropdown"
                          >Status</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Actived</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Pendig</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Blocked</span></a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li class="nk-block-tools-opt">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="btn btn-icon btn-primary d-md-none"
                        ><em class="icon ni ni-plus"></em></a
                      ><a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="btn btn-primary d-none d-md-inline-flex"
                        ><em class="icon ni ni-plus"></em><span>Add</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="nk-block">
          <div class="nk-tb-list is-separate mb-3">
            <div class="nk-tb-item nk-tb-head">
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid"
                  /><label class="custom-control-label" for="uid"></label>
                </div>
              </div>
              <div class="nk-tb-col">
                <span class="sub-text">Team Member</span>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="sub-text">Title</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Phone</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Email</span>
              </div>
              <!-- <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Facebook</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Instagram</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Twitter</span>
              </div> -->
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Status</span>
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1 my-n1">
                  <li>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger mr-n1"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-na"></em
                              ><span>Suspend Selected</span></a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-trash"></em
                              ><span>Remove Seleted</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="nk-tb-item"
              v-for="(member, i) in members"
              :key="'member' + i"
            >
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid1"
                  /><label class="custom-control-label" for="uid1"></label>
                </div>
              </div>
              <div class="nk-tb-col">
                <div class="user-card">
                  <div class="user-avatar bg-lighter">
                    <img
                      :src="apiURL + '/' + member.avatar"
                      v-if="member.avatar"
                      :alt="member.name"
                      style="cursor: pointer"
                      @click="editMemberAvatar(member)"
                    />
                    <img
                      :src="`${publicPath}img/avatar.png`"
                      v-else
                      :alt="member.name"
                      style="cursor: pointer"
                      @click="editMemberAvatar(member)"
                    />
                  </div>
                  <div class="user-info">
                    <span class="tb-lead"
                      >{{ member.name }}
                      <span class="dot dot-success d-md-none ml-1"></span></span
                    ><span>{{ member.email || "No Email Yet" }}</span>
                  </div>
                </div>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span>{{ member.title || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span>{{ member.phone || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span>{{ member.email || "N/A" }}</span>
              </div>
              <!-- <div class="nk-tb-col tb-col-md">
                <span>{{ member.facebook_url || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span>{{ member.instagram_url || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span>{{ member.twitter_url || "N/A" }}</span>
              </div> -->
              <div class="nk-tb-col tb-col-md">
                <span class="tb-status text-success" v-if="member.active == 1"
                  >Active</span
                >
                <span class="tb-status text-warning" v-if="member.active == 2"
                  >Pending</span
                >
                <span class="tb-status text-danger" v-if="member.active == 0"
                  >Suspended</span
                >
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1">
                  <li>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="selectUser(member)"
                              ><em class="icon ni ni-pen"></em
                              ><span>Edit</span></a
                            >
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                handleDeleteAlertModal({
                                  tableName: 'members',
                                  entityIdentifer: 'Team Member',
                                  entity: `${member.name}`,
                                  url: `admin/members/delete/${member.id}`,
                                })
                              "
                              ><em class="icon ni ni-trash"></em
                              ><span>Remove</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyPaginations from "@/components/Paginations.vue";
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "Team",
  mixins: [dashboardServices],
  components: {
    pagination: MyPaginations,
  },
  data: () => ({
    members: [],
    newMember: {
      name: null,
      title: null,
      about: null,
      facebook_url: null,
      instagram_url: null,
      twitter_url: null,
      email: null,
      phone: null,
      active: 1,
    },
    errorsMessages: {},
  }),
  computed: {
    validationErrors() {
      let errors = Object.values(this.errorsMessages);
      errors = errors.flat();
      return errors;
    },
  },
  created() {
    this.$store
      .dispatch("getRequest", "admin/members/list")
      .then((response) => {
        this.members = response.data.members;
      });
  },
  methods: {
    addMember() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.emailError = false;
          this.phoneError = false;
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.newMember),
              url: "admin/members/create",
            })
            .then((response) => {
              if (response.data.status) {
                if (this.newMember.id) location.reload();
                else this.members = [...response.data.members, ...this.members];
                this.successMessage = true;
                this.$validator.reset();
                this.toggleModal();

                this.clearObject(this.newMember);
              } else {
                this.errorsMessages = response.data.message;
              }
            });
        }
      });
    },
    selectUser(user) {
      this.newMember = {
        ...user,
      };
      this.toggleModal();
    },
    editMemberAvatar(member) {
      this.handleEntityImageModify({
        record_id: member.id,
        url: `admin/members/change-avatar`,
      });
    },
  },
};
</script>
