<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Gallery</h3>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="row g-3">
            <div class="col-lg-12">
              <h6>Images</h6>
              <hview-upload
                :input-id="'auto-gallery'"
                :extentions="['png', 'jpg', 'jpeg', 'gif']"
                @after-uploading="handleUploadedGalleryItem"
                :post-data="{
                  uploadPath: $store.state.projectMediaPath,
                }"
              ></hview-upload>
              <template v-if="newGallery.data_values.length">
                <div class="row border-bottom py-2 align-items-center">
                  <div
                    class="col-lg-2"
                    v-for="(tempImg, key) in newGallery.data_values"
                    :key="'gallery' + key"
                  >
                    <div class="d-block align-items-center">
                      <div class="p-1">
                        <img
                          :src="apiURL + '/' + tempImg"
                          class="img img-thumbnail rounded img-fluid"
                        />
                      </div>
                      <div class="text-center">
                        <a
                          href="javascript:void(0)"
                          @click.prevent="handleGalleryReset(key, tempImg)"
                          class="btn btn-sm btn-outline-danger p-0"
                          style="font-size: 10px; border: none"
                        >
                          <span class="icon ni ni-cross"></span> Remove
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right mt-2">
                  <button
                    class="btn btn-secondary btn-sm"
                    type="button"
                    @click="showFilePicker('auto-gallery')"
                  >
                    Add New
                  </button>
                </div>
              </template>
              <div
                class="jb-gallery-upload"
                v-else
                @click="showFilePicker('auto-gallery')"
              >
                <p class="text-center mb-0">
                  <img
                    :src="`${publicPath}img/upload-icon.png`"
                    class="img img-fluid"
                  />
                </p>
                <p class="text-mutted">No images available in the gallery</p>
              </div>
            </div>
            <div class="col-2">
              <wolf-button
                caption="Save Changes"
                @btn-role="handleSubmit"
                :disabler="newGallery.data_values.length == 0"
                activator="saving_info"
              ></wolf-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Gallery",
  data: () => ({
    newGallery: {
      data_keys: "gallery",
      data_values: [],
      votes: null,
    },
  }),
  created() {
    this.$store
      .dispatch("getRequest", `admin/frontends/list/gallery`)
      .then((response) => {
        this.newGallery.data_values = response.data.row.data_values;
      });
  },
  methods: {
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.newGallery),
              url: "admin/frontends/create",
            })
            .then((response) => {
              if (response.data.status) {
              }
            });
        }
      });
    },
    handleUploadedGalleryItem(file) {
      this.newGallery.data_values.push(file.file_path);
    },
    handleGalleryReset(key, filename) {
      if (confirm("Are you want to remove this image from Gallery?")) {
        this.$store
          .dispatch("postRequest", {
            formData: this.formData({
              storedFile: filename,
            }),
            url: "media/remove-stored-file",
          })
          .then(() => {
            if (this.newGallery.data_values.includes(filename)) {
              this.newGallery.data_values.splice(key, 1);
            }
          });
      }
    },
  },
};
</script>
