<template>
  <div class="container-fluid">
    <off-canvas v-if="modalOpen" @close="toggleModal">
      <span slot="head">
        {{ newUser.id ? "Update User" : "Add a new User" }}
      </span>
      <div slot="body">
        <form>
          <div class="form-group">
            <label>Firstname</label>
            <input
              type="text"
              v-model="newUser.first_name"
              name="Firstname"
              v-validate="'required'"
              :class="{ 'is-invalid': errors.has('Firstname') }"
              class="form-control form-control-lg"
              placeholder="Firstname"
            />
            <div class="invalid-feedback">
              {{ errors.first("Firstname") }}
            </div>
          </div>
          <div class="form-group">
            <label>Lastname</label>
            <input
              type="text"
              v-model="newUser.last_name"
              name="Lastname"
              v-validate="'required'"
              :class="{ 'is-invalid': errors.has('Lastname') }"
              class="form-control form-control-lg"
              placeholder="Lastname"
            />
            <span class="invalid-feedback">{{ errors.first("Lastname") }}</span>
            
          </div>
          <div class="form-group">
            <label>Email address</label>
            <input
              type="text"
              v-model="newUser.email"
              name="Email"
              class="form-control"
              placeholder="Email address"
            />
          </div>
          <div class="form-group">
             <label>Phone Number</label>
            <input
              type="text"
              v-model="newUser.phone"
              name="Phone"
              v-validate="'required|digits:10'"
              :class="{ 'is-invalid': errors.has('Phone') }"
              class="form-control form-control-lg"
              placeholder="Phone number"
            />
            <span class="invalid-feedback">{{ errors.first("Phone") }}</span>
           
          </div>
          <div class="form-group">
             <label>Password</label>
            <input
              type="password"
              v-model="newUser.password"
              name="Password"
              v-validate="'required'"
              :class="{ 'is-invalid': errors.has('Password') }"
              class="form-control form-control-lg"
              placeholder="Password"
            />
            <span class="invalid-feedback">{{ errors.first("Password") }}</span>
           
          </div>
          <div v-if="!empty(validationErrors)" class="mb-3">
            <ul class="alert alert-danger">
              <li
                v-for="(value, key, index) in validationErrors"
                :key="'error' + key + index"
              >
                {{ value }}
              </li>
            </ul>
          </div>
          
          <div class="mb-2">
            <div class="d-flex">
              <wolf-button
                caption="Confirm and Save"
                @btn-role="addUser"
                classes="btn btn-primary btn-lg ml-auto"
                :disabler="!newUser.first_name"
                activator="saving_user"
              ></wolf-button>
            </div>
          </div>
        </form>
      </div>
    </off-canvas>
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Users Lists</h3>
              <div class="nk-block-des text-soft">
                <p>You have total {{ users.length }} users.</p>
              </div>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a
                  href="javascript:void(0)"
                  class="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="more-options"
                  ><em class="icon ni ni-more-v"></em
                ></a>
                <div class="toggle-expand-content" data-content="more-options">
                  <ul class="nk-block-tools g-3">
                    <li>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right">
                          <em class="icon ni ni-search"></em>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="default-04"
                          placeholder="Search by name"
                        />
                      </div>
                    </li>
                    <li>
                      <div class="dropdown">
                        <a
                          href="javascript:void(0)"
                          class="
                            dropdown-toggle dropdown-indicator
                            btn btn-outline-light btn-white
                          "
                          data-toggle="dropdown"
                          >Status</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Actived</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Pendig</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Blocked</span></a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li class="nk-block-tools-opt">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="btn btn-icon btn-primary d-md-none"
                        ><em class="icon ni ni-plus"></em></a
                      ><a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="btn btn-primary d-none d-md-inline-flex"
                        ><em class="icon ni ni-plus"></em><span>Add</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="nk-block">
          <div class="nk-tb-list is-separate mb-3">
            <div class="nk-tb-item nk-tb-head">
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid"
                  /><label class="custom-control-label" for="uid"></label>
                </div>
              </div>
              <div class="nk-tb-col"><span class="sub-text">User</span></div>
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Phone</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Address</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Last Login</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Status</span>
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1 my-n1">
                  <li>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger mr-n1"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-na"></em
                              ><span>Suspend Selected</span></a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-trash"></em
                              ><span>Remove Seleted</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="nk-tb-item"
              v-for="(user, i) in users"
              :key="'user' + i"
            >
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid1"
                  /><label class="custom-control-label" for="uid1"></label>
                </div>
              </div>
              <div class="nk-tb-col">
                <router-link
                  :to="{
                    name: ''
                  }"
                  ><div class="user-card">
                    <div class="user-avatar bg-lighter">
                      <img
                        :src="apiURL + '/' + user.avatar"
                        v-if="user.avatar"
                        :alt="user.first_name"
                        @click="editUserImage(user)"
                      />
                      <img
                        :src="`${publicPath}img/avatar.png`"
                        v-else
                        :alt="user.first_name"
                        @click="editUserImage(user)"
                      />
                    </div>
                    <div class="user-info">
                      <span class="tb-lead"
                        >{{ user.name }}
                        <span
                          class="dot dot-success d-md-none ml-1"
                        ></span></span
                      ><span>{{ user.email || "No Email Yet" }}</span>
                    </div>
                  </div></router-link
                >
              </div>
              <div class="nk-tb-col tb-col-md">
                <span>{{ user.phone || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span>{{ user.address || "Kigali, Rwanda" }}</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span>{{ chatDate(user.last_login) || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="tb-status text-success" v-if="user.status == 1"
                  >Active</span
                >
                <span class="tb-status text-warning" v-if="user.status == 2"
                  >Pending</span
                >
                <span class="tb-status text-danger" v-if="user.status == 0"
                  >Suspended</span
                >
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1">
                  <li>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="javascript:void(0)" @click.prevent="selectUser(user)"
                              ><em class="icon ni ni-pen"></em
                              ><span>Edit</span></a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0)"
                            @click.prevent="
                                handleDeleteAlertModal({
                                  tableName: 'users',
                                  entityIdentifer: 'User',
                                  entity: `${user.name}`,
                                  url: `admin/users/delete/${user.id}`,
                                })
                              "
                              ><em class="icon ni ni-trash"></em
                              ><span>Reset Pass</span></a
                            >
                          </li>
                          <li class="divider"></li>
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-shield-star"></em
                              ><span>Reset Pass</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "Users",
  mixins: [dashboardServices],
  data: () => ({
    users: [],
    newUser: {
      first_name: null,
      address: null,
      last_name: null,
      password: null,
      email: null,
      phone: null,
      account_type: 1,
    },
    emailError: false,
    phoneError: false,
    errorsMessages: {},
  }),
  computed: {
    validationErrors() {
      let errors = Object.values(this.errorsMessages);
      errors = errors.flat();
      return errors;
    },
  },
  created() {
    this.$store
      .dispatch("getRequest", "admin/users/list")
      .then((response) => {
        this.users = response.data.users;
      });
  },
  methods: {
    addUser() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.emailError = false;
          this.phoneError = false;
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.newUser),
              url: "admin/users/create",
            })
            .then((response) => {
              if (response.data.status) {
                this.successMessage = true;
                this.clearObject(this.newUser);
                this.$validator.reset();
                this.toggleModal();
                this.users = response.data.users;
              } else {
                this.errorsMessages = response.data.message;
              }
            });
        }
      });
    },
    selectUser(user) {
      this.newUser = {
        ...user,
      };
      this.toggleModal();
    },
    editUserImage(user) {
      this.handleEntityImageModify({
        record_id: user.id,
        url: `admin/users/change-avatar`,
      });
    },
  },
};
</script>
