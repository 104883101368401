<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Dashboard</h3>
            </div>
            <div class="nk-block-head-content" v-if="false">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a
                  href="javascript:void(0)"
                  class="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="pageMenu"
                  ><em class="icon ni ni-more-v"></em
                ></a>
                <div class="toggle-expand-content" data-content="pageMenu">
                  <ul class="nk-block-tools g-3">
                    <li>
                      <div class="drodown">
                        <a
                          href="javascript:void(0)"
                          class="
                            dropdown-toggle
                            btn btn-white btn-dim btn-outline-light
                          "
                          data-toggle="dropdown"
                          aria-expanded="false"
                          ><em
                            class="d-none d-sm-inline icon ni ni-calender-date"
                          ></em
                          ><span
                            ><span class="d-none d-md-inline">Last</span> 30
                            Days</span
                          ><em class="dd-indc icon ni ni-chevron-right"></em
                        ></a>
                        <div class="dropdown-menu dropdown-menu-right" style="">
                          <ul class="link-list-opt no-bdr">
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Last 30 Days</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Last 6 Months</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Last 1 Years</span></a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li class="nk-block-tools-opt">
                      <a href="javascript:void(0)" class="btn btn-primary"
                        ><em class="icon ni ni-reports"></em
                        ><span>Reports</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="row g-gs">
            <div
              class="col-xxl-2 col-sm-4"
              v-for="(card, i) in cards"
              :key="'card' + i"
            >
              <div class="card">
                <div class="nk-ecwg nk-ecwg6">
                  <div class="card-inner">
                    <div class="card-title-group">
                      <div class="card-title">
                        <h6 class="title">{{ card.title }}</h6>
                      </div>
                    </div>
                    <div class="data">
                      <div class="data-group w-100">
                        <div class="align-center justify-between w-100">
                          <div class="g">
                            <div class="amount">
                              {{ addComma(card.numbers) || 0 }}
                            </div>
                          </div>
                          <router-link :to="{ name:card.router }" class="g">
                            View All
                            <span class="btn btn-icon btn-trigger mr-n1"
                              ><em class="icon ni ni-chevron-right"></em
                            ></span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Dashboard",
  components: {},
  data: () => ({
    cards: [],
  }),
  beforeCreate() {
    this.$store
      .dispatch("getRequest", "admin/dashboard/stats")
      .then((response) => {
        this.cards = response.data.cards;
      });
  },
  methods: {},
  mounted() {
    [
      ...document.querySelectorAll("li.dropdown a[data-toggle='dropdown']"),
    ].forEach((item) => {
      item.addEventListener("click", () => {
        item.parentElement.classList.toggle("show");
        item.parentElement
          .querySelector("div.dropdown-menu")
          .classList.toggle("show");
      });
    });
  },
};
</script>
<style lang="css"></style>
