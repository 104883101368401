<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Company Values</h3>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="card">
            <div class="card-body">
              <div class="row g-3">
                <div class="col-lg-3">
                  <div class="d-flex align-items-center">
                    <div class="w-100">
                      <label class="form-label">Image</label>
                      <hview-upload
                        :input-id="'cover-image'"
                        @after-uploading="handleUploadedImage"
                      ></hview-upload>
                      <div v-if="values.data_values[0].image">
                        <div
                          class="jb-gallery-upload text-center py-1"
                          style="height: auto !important"
                        >
                          <img
                            :src="apiURL + '/' + values.data_values[0].image"
                            class="img img-thumbails rounded img-fluid"
                          />
                          <a
                            class="text-danger mt-2 w-100"
                            href="javascript:void(0)"
                            @click.prevent="showFilePicker('cover-image')"
                          >
                            <i class="ri-upload-cloud-line"></i>
                            Change image</a
                          >
                        </div>
                      </div>

                      <div
                        class="jb-gallery-upload"
                        style="height: 25.5rem"
                        v-else
                        @click="showFilePicker('cover-image')"
                      >
                        <p class="text-center">
                          <img
                            :src="`${publicPath}img/placeholder-small.png`"
                            style="width: 80px"
                            class="img img-fluid"
                          />
                        </p>
                        <p class="text-mutted text-center">
                          Click to add image
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9">
                  <section>
                    <h5>Main title</h5>
                    <div class="form-group">
                      <label for="">Title</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Main title"
                        v-model="values.data_values[0].title"
                      />
                    </div>
                    <div class="form-group">
                      <label for="">Summary</label>
                      <textarea
                        class="form-control"
                        placeholder="Summary"
                        v-model="values.data_values[0].summary"
                      ></textarea>
                    </div>
                  </section>
                  <section class="py-5">
                    <h5>Sections</h5>
                    <article
                      v-for="(article, i) in values.data_values[0].sections"
                      :key="'article' + i"
                      class="py-4"
                    >
                      <div class="form-group">
                        <label for="">Title</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="title"
                          v-model="values.data_values[0].sections[i].title"
                        />
                      </div>
                      <div class="form-group">
                        <label for="">Description</label>
                        <textarea
                          class="form-control"
                          placeholder="Summary"
                          v-model="
                            values.data_values[0].sections[i].description
                          "
                        ></textarea>
                      </div>
                      <div class="d-flex align-items-center">
                        <button
                          type="button"
                          class="btn btn-sm btn-danger"
                          @click="removeSection(i)"
                          v-if="i > 0"
                        >
                          Remove
                        </button>
                        <button
                          type="button"
                          class="btn btn-sm btn-dark ml-auto"
                          v-if="i == values.data_values[0].sections.length - 1"
                          @click="addSection"
                        >
                          Add New
                        </button>
                      </div>
                    </article>
                  </section>
                </div>
                <hr />
                <div class="col-2">
                  <wolf-button
                    caption="Save Changes"
                    @btn-role="handleSubmit"
                    :disabler="
                      !values.data_values[0].image ||
                      !values.data_values[0].title
                    "
                    activator="saving_info"
                  ></wolf-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Values",
  data: () => ({
    values: {
      data_keys: "values",
      data_values: [
        {
          image: null,
          title: null,
          summary: null,
          sections: [
            {
              title: null,
              description: null,
            },
          ],
        },
      ],
      votes: null,
    },
  }),
  created() {
    this.$store
      .dispatch("getRequest", `admin/frontends/list/values`)
      .then((response) => {
        if (response.data.row.data_values) {
          this.values.data_values = response.data.row.data_values;
        }
      });
  },
  methods: {
    addSection() {
      this.values.data_values[0].sections.push({
        title: null,
        description: null,
      });
    },
    removeSection(i) {
      this.values.data_values[0].sections.splice(i, 1);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.values),
              url: "admin/frontends/create",
            })
            .then((response) => {
              if (response.data.status) {
              }
            });
        }
      });
    },
    handleUploadedImage(file) {
      this.values.data_values[0].image = file.file_path;
      console.log(this.values.data_values);
    },
  },
};
</script>
