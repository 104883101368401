<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Products</h3>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a
                  href="javascript:void(0)"
                  class="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="pageMenu"
                  ><em class="icon ni ni-more-v"></em
                ></a>
                <div class="toggle-expand-content" data-content="pageMenu">
                  <ul class="nk-block-tools g-3">
                    <li>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right">
                          <em class="icon ni ni-search"></em>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="default-04"
                          placeholder="Quick search by id"
                        />
                      </div>
                    </li>
                    <li class="nk-block-tools-opt">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="toggle btn btn-icon btn-primary d-md-none"
                        ><em class="icon ni ni-plus"></em></a
                      ><a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="toggle btn btn-primary d-none d-md-inline-flex"
                        ><em class="icon ni ni-plus"></em
                        ><span>Add Product</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="nk-tb-list is-separate mb-3">
            <div class="nk-tb-item nk-tb-head">
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="pid"
                  /><label class="custom-control-label" for="pid"></label>
                </div>
              </div>
              <div class="nk-tb-col tb-col-sm"><span>Name</span></div>
              <div class="nk-tb-col"><span>SKU</span></div>
              <div class="nk-tb-col"><span>Price</span></div>
              <div class="nk-tb-col tb-col-md"><span>Category</span></div>
              <div class="nk-tb-col tb-col-md">
                <em class="tb-asterisk icon ni ni-star-round"></em>
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1 my-n1">
                  <li class="mr-n1">
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger"
                        data-toggle="dropdown"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-trash"></em
                              ><span>Remove Selected</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="nk-tb-item"
              v-for="(product, i) in products"
              :key="'product' + i"
            >
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="pid1"
                  /><label class="custom-control-label" for="pid1"></label>
                </div>
              </div>
              <div class="nk-tb-col tb-col-sm">
                <span class="tb-product"
                  ><img
                    :src="apiURL + '/' + product.image"
                    v-if="product.image"
                    class="thumb"
                  />
                  <img
                    :src="`${publicPath}img/placeholder-small.png`"
                    v-else
                    class="thumb"
                  />
                  <span class="title">{{ product.title }}</span>
                </span>
              </div>
              <div class="nk-tb-col">
                <span class="tb-sub">{{ product.code }}</span>
              </div>
              <div class="nk-tb-col">
                <span class="tb-lead"
                  >RWF {{ addComma(product.price) || 0 }}</span
                >
              </div>

              <div class="nk-tb-col tb-col-md">
                <span class="tb-sub">{{
                  product.category || "Not Category Yet"
                }}</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <div class="asterisk tb-asterisk">
                  <a href="javascript:void(0)"
                    ><em class="asterisk-off icon ni ni-star"></em
                    ><em class="asterisk-on icon ni ni-star-fill"></em
                  ></a>
                </div>
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1 my-n1">
                  <li class="mr-n1">
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger"
                        data-toggle="dropdown"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="selectProduct(product)"
                              ><em class="icon ni ni-edit"></em
                              ><span>Edit Product</span></a
                            >
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                handleDeleteAlertModal({
                                  tableName: 'products',
                                  entityIdentifer: 'Product',
                                  entity: `${product.title}`,
                                  url: `admin/products/delete/${product.id}`,
                                })
                              "
                              ><em class="icon ni ni-trash"></em
                              ><span>Remove Product</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <product-creator
      v-if="modalOpen"
      @saved="getNewProduct"
      @close="toggleModal"
      :product="product"
    ></product-creator>
  </div>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import ProductCreator from "./ProductCreator.vue";
export default {
  name: "Products",
  mixins: [dashboardServices],
  components: { ProductCreator },
  data: () => ({
    products: [],
    product: {},
  }),
  computed: {},
  beforeCreate() {
    this.$store
      .dispatch("getRequest", `admin/products/list`)
      .then((response) => {
        if (response.data.status) {
          this.products = response.data.products || [];
        }
      });
  },
  methods: {
    getNewProduct(product) {
      this.products = [...product, ...this.products];
    },
    selectProduct(product) {
      this.product = { ...product };
      this.toggleModal();
    },
  },
};
</script>
