<template>
  <section class="container-fluid">
    <hview-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px">
        {{ newPartner.id ? "Update Partner" : "Add a new Partner" }}
      </span>
      <div slot="body">
        <form class="forms-sample">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label">Company Name</label>
                <input
                  type="text"
                  v-model="newPartner.partner_name"
                  name="Business Name"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('Business Name') }"
                  class="form-control"
                  placeholder="Business Name"
                />
                <div class="invalid-feedback">
                  {{ errors.first("Business Name") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label">Website URL</label>
                <input
                  type="text"
                  v-model="newPartner.website"
                  name="Website"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('Website') }"
                  class="form-control"
                  placeholder="Website URL"
                />
                <div class="invalid-feedback">
                  {{ errors.first("Website") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label">Phone Number</label>
                <input
                  type="text"
                  v-model="newPartner.phone"
                  class="form-control"
                  placeholder="Business Phone"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label">Email</label>
                <input
                  type="text"
                  v-model="newPartner.email"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label">Description</label>
                <textarea
                  type="text"
                  v-model="newPartner.description"
                  name="description"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('description') }"
                  class="form-control"
                  placeholder="Write a brief comments"
                ></textarea>
                <div class="invalid-feedback">
                  {{ errors.first("description") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-center">
                <div class="w-100">
                  <label class="form-label">Logo</label>
                  <files-uploader
                    :input-id="'cover-image'"
                    @after-uploading="handleUploadedImage"
                  ></files-uploader>
                  <div v-if="newPartner.partner_logo">
                    <div
                      class="jb-gallery-upload text-center py-1"
                      style="height: auto !important"
                    >
                      <img
                        :src="apiURL + '/' + newPartner.partner_logo"
                        class="img img-thumbails rounded img-fluid"
                      />
                      <a
                        class="text-danger mt-2 w-100"
                        href="javascript:void(0)"
                        @click.prevent="showFilePicker('cover-image')"
                      >
                        <i class="ri-upload-cloud-line"></i>
                        Change image</a
                      >
                    </div>
                  </div>

                  <div
                    class="jb-gallery-upload"
                    style="height: 8.5rem"
                    v-else
                    @click="showFilePicker('cover-image')"
                  >
                    <p class="text-center">
                      <img
                        :src="`${publicPath}img/placeholder-small.png`"
                        style="width: 80px"
                        class="img img-fluid"
                      />
                    </p>
                    <p class="text-mutted text-center">
                      Click to add partner logo
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>

          <div class="my-3">
            <wolf-button
              classes="btn btn-primary"
              caption="Save and Close"
              @btn-role="addPartner"
              :disabler="!newPartner.partner_logo || !newPartner.partner_name"
              activator="saving_records"
            ></wolf-button>
          </div>
        </form>
      </div>
    </hview-modal>

    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">
                List of all Registered Partners
              </h3>
              <div class="nk-block-des text-soft">
                <p>You have total {{ partners.length }} partners.</p>
              </div>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a
                  href="javascript:void(0)"
                  class="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="more-options"
                  ><em class="icon ni ni-more-v"></em
                ></a>
                <div class="toggle-expand-content" data-content="more-options">
                  <ul class="nk-block-tools g-3">
                    <li>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right">
                          <em class="icon ni ni-search"></em>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="default-04"
                          placeholder="Search by name"
                        />
                      </div>
                    </li>
                    <li>
                      <div class="dropdown">
                        <a
                          href="javascript:void(0)"
                          class="
                            dropdown-toggle dropdown-indicator
                            btn btn-outline-light btn-white
                          "
                          data-toggle="dropdown"
                          >Status</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Actived</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Disabled</span></a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li class="nk-block-tools-opt">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="btn btn-icon btn-primary d-md-none"
                        ><em class="icon ni ni-plus"></em></a
                      ><a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="btn btn-primary d-none d-md-inline-flex"
                        ><em class="icon ni ni-plus"></em><span>Add</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="nk-tb-list is-separate mb-3">
            <div class="nk-tb-item nk-tb-head">
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid"
                  /><label class="custom-control-label" for="uid"></label>
                </div>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="sub-text">Logo</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Business Name</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Website</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Phone</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Email</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Address</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Status</span>
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1 my-n1">
                  <li>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger mr-n1"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-na"></em
                              ><span>Disable Selected</span></a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-trash"></em
                              ><span>Remove Seleted</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="nk-tb-item"
              v-for="(partner, i) in partners"
              :key="'partner' + i"
            >
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid1"
                  /><label class="custom-control-label" for="uid1"></label>
                </div>
              </div>
              <div class="nk-tb-col">
                <span class="tb-product"
                  ><img
                    :src="apiURL + '/' + partner.partner_logo"
                    v-if="partner.partner_logo"
                    @click="editPartnerLogo(partner)"
                    class="thumb"
                  />
                  <img
                    :src="`${publicPath}img/placeholder-small.png`"
                    v-else
                    @click="editPartnerLogo(partner)"
                    class="thumb"
                  />
                </span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span>{{ partner.partner_name || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="tb-amount">{{ partner.website || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="tb-amount">{{ partner.phone || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="tb-amount">{{ partner.email || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="tb-amount">{{ partner.address || "N/A" }}</span>
              </div>

              <div class="nk-tb-col tb-col-md">
                <span class="tb-status text-success" v-if="partner.enabled == 1"
                  >Active</span
                >
                <span class="tb-status text-danger" v-if="partner.enabled == 0"
                  >Disabled</span
                >
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1">
                  <li>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="selectPartner(partner)"
                              ><em class="icon ni ni-pen"></em
                              ><span>Edit</span></a
                            >
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                handleDeleteAlertModal({
                                  tableName: 'Partner',
                                  entityIdentifer: 'Partner',
                                  entity: `${partner.partner_name}`,
                                  url: `admin/partners/delete/${partner.id}`,
                                })
                              "
                              ><em class="icon ni ni-trash"></em
                              ><span>Remove</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import WolfFilesUploader from "@/components/WolfFilesUploader.vue";
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "Partners",
  mixins: [dashboardServices],
  components: {
    "files-uploader": WolfFilesUploader,
  },
  data: () => ({
    newPartner: {
      website: null,
      partner_name: null,
      partner_logo: null,
      email: null,
      address: null,
      phone: null,
      description: null,
      enabled: 1,
    },
    partners: [],
  }),
  created() {
    this.$store
      .dispatch("getRequest", "admin/partners/list")
      .then((response) => {
        this.partners = response.data.partners;
      });
  },
  methods: {
    handleUploadedImage(file) {
      this.newPartner.partner_logo = file.file_path;
    },
    addPartner() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.newPartner),
              url: "admin/partners/create",
            })
            .then((response) => {
              if (response.data.status) {
                if (this.newPartner.id) location.reload();
                else
                  this.partners = [...response.data.partners, ...this.partners];
                this.clearObject(this.newPartner);
                this.$validator.reset();
                this.toggleModal();
              }
            });
        }
      });
    },
    selectPartner(partner) {
      this.newPartner = { ...partner };
      this.toggleModal();
    },
  },
};
</script>
