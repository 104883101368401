<template>
  <section class="container-fluid">
    <hview-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px">
        {{ newService.id ? "Update service" : "Add a new service" }}
      </span>
      <div slot="body">
        <form class="forms-sample">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-3">
                <label class="form-label">Service Name</label>
                <input
                  type="text"
                  v-model="newService.title"
                  name="Service title"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('Service title') }"
                  class="form-control"
                  placeholder="Service title"
                />
                <div class="invalid-feedback">
                  {{ errors.first("Service title") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-3">
                <label class="form-label">Description</label>
                <textarea
                  type="text"
                  v-model="newService.description"
                  name="Description"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('Description') }"
                  class="form-control"
                  placeholder="Service Description"
                ></textarea>
                <div class="invalid-feedback">
                  {{ errors.first("Description") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-center">
                <div class="w-100">
                  <label class="form-label">Service Image</label>
                  <files-uploader
                    :input-id="'cover-image'"
                    @after-uploading="handleUploadedImage"
                  ></files-uploader>
                  <div v-if="newService.image">
                    <div
                      class="jb-gallery-upload text-center py-1"
                      style="height: auto !important"
                    >
                      <img
                        :src="apiURL + '/' + newService.image"
                        class="img img-thumbails rounded img-fluid"
                      />
                      <a
                        class="text-danger mt-2 w-100"
                        href="javascript:void(0)"
                        @click.prevent="showFilePicker('cover-image')"
                      >
                        <i class="ri-upload-cloud-line"></i>
                        Change image</a
                      >
                    </div>
                  </div>

                  <div
                    class="jb-gallery-upload"
                    style="height: 8.5rem"
                    v-else
                    @click="showFilePicker('cover-image')"
                  >
                    <p class="text-center">
                      <img
                        :src="`${publicPath}img/placeholder-small.png`"
                        style="width: 80px"
                        class="img img-fluid"
                      />
                    </p>
                    <p class="text-mutted text-center">Click to add service</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>

          <div class="my-3">
            <wolf-button
              classes="btn btn-primary"
              caption="Save and Close"
              @btn-role="addService"
              :disabler="!newService.title"
              activator="saving_records"
            ></wolf-button>
          </div>
        </form>
      </div>
    </hview-modal>

    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">List of all services</h3>
              <div class="nk-block-des text-soft">
                <p>You have total {{ services.length }} services.</p>
              </div>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a
                  href="javascript:void(0)"
                  class="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="more-options"
                  ><em class="icon ni ni-more-v"></em
                ></a>
                <div class="toggle-expand-content" data-content="more-options">
                  <ul class="nk-block-tools g-3">
                    <li>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right">
                          <em class="icon ni ni-search"></em>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="default-04"
                          placeholder="Search by name"
                        />
                      </div>
                    </li>
                    <li>
                      <div class="dropdown">
                        <a
                          href="javascript:void(0)"
                          class="
                            dropdown-toggle dropdown-indicator
                            btn btn-outline-light btn-white
                          "
                          data-toggle="dropdown"
                          >Status</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Actived</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Disabled</span></a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li class="nk-block-tools-opt">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="btn btn-icon btn-primary d-md-none"
                        ><em class="icon ni ni-plus"></em></a
                      ><a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="btn btn-primary d-none d-md-inline-flex"
                        ><em class="icon ni ni-plus"></em><span>Add</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="nk-tb-list is-separate mb-3">
            <div class="nk-tb-item nk-tb-head">
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid"
                  /><label class="custom-control-label" for="uid"></label>
                </div>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="sub-text">Image</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Title</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Description</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Status</span>
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1 my-n1">
                  <li>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger mr-n1"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-na"></em
                              ><span>Disable Selected</span></a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-trash"></em
                              ><span>Remove Seleted</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="nk-tb-item"
              v-for="(service, i) in services"
              :key="'service' + i"
            >
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid1"
                  /><label class="custom-control-label" for="uid1"></label>
                </div>
              </div>
              <div class="nk-tb-col">
                <span class="tb-product"
                  ><img
                    :src="apiURL + '/' + service.image"
                    v-if="service.image"
                    @click="editServiceImage(service)"
                    class="thumb"
                  />
                  <img
                    :src="`${publicPath}img/placeholder-small.png`"
                    v-else
                    @click="editServiceImage(service)"
                    class="thumb"
                  />
                </span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span>{{ service.title || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="tb-amount">{{
                  service.description || "N/A"
                }}</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="tb-status text-success" v-if="service.enabled == 1"
                  >Active</span
                >
                <span class="tb-status text-danger" v-if="service.enabled == 0"
                  >Disabled</span
                >
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1">
                  <li>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="selectService(service)"
                              ><em class="icon ni ni-pen"></em
                              ><span>Edit</span></a
                            >
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                handleDeleteAlertModal({
                                  tableName: 'services',
                                  entityIdentifer: 'Service',
                                  entity: `${service.title}`,
                                  url: `admin/services/delete/${service.id}`,
                                })
                              "
                              ><em class="icon ni ni-trash"></em
                              ><span>Remove</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import WolfFilesUploader from "@/components/WolfFilesUploader.vue";
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "Services",
  mixins: [dashboardServices],
  components: {
    "files-uploader": WolfFilesUploader,
  },
  data: () => ({
    newService: {
      title: null,
      image: null,
      description: null,
      enabled: 1,
    },
    services: [],
  }),
  created() {
    this.$store
      .dispatch("getRequest", "admin/services/list")
      .then((response) => {
        this.services = response.data.services;
      });
  },
  methods: {
    handleUploadedImage(file) {
      this.newService.image = file.file_path;
    },
    addService() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.newService),
              url: "admin/services/create",
            })
            .then((response) => {
              if (response.data.status) {
                if (this.newService.id) location.reload();
                else
                  this.services = [...response.data.services, ...this.services];
                this.clearObject(this.newService);
                this.$validator.reset();
                this.toggleModal();
              }
            });
        }
      });
    },
    selectService(service) {
      this.newService = { ...service };
      this.toggleModal();
    },
  },
};
</script>
