<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">About Company</h3>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h3>General Info</h3>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="">Sloga (About title)</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Sloga"
                      v-model="about.data_values[0].sloga"
                    />
                  </div>

                  <div class="form-group">
                    <label for="">Who We Are</label>
                    <textarea
                      class="form-control"
                      placeholder="About The Company"
                      v-model="about.data_values[0].whoWeAre"
                    ></textarea>
                  </div>

                  <div class="form-group">
                    <label for="">Strategy</label>
                    <textarea
                      class="form-control"
                      placeholder="Strategy and Mission"
                      v-model="about.data_values[0].strategy"
                    ></textarea>
                  </div>

                  <div class="form-group">
                    <label for="">Mission</label>
                    <textarea
                      class="form-control"
                      placeholder="Mission"
                      v-model="about.data_values[0].mission"
                    ></textarea>
                  </div>

                  <div class="form-group">
                    <label for="">Vision</label>
                    <textarea
                      class="form-control"
                      placeholder="Vision"
                      v-model="about.data_values[0].vision"
                    ></textarea>
                  </div>

                  <div class="form-group">
                    <label for=""
                      >Objectives (<em>Separate them with <b>|</b></em
                      >)</label
                    >
                    <textarea
                      class="form-control"
                      placeholder="Vision"
                      v-model="about.data_values[0].objectives"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row g-3">
                <div class="col-12">
                  <h3>About Image &amp; Why Choose Us</h3>
                </div>
                <div class="col-lg-5">
                  <div class="d-flex align-items-center">
                    <div class="w-100">
                      <label class="form-label">About Image</label>
                      <hview-upload
                        :input-id="'cover-image'"
                        @after-uploading="handleUploadedImage"
                      ></hview-upload>
                      <div v-if="about.data_values[0].image">
                        <div
                          class="jb-gallery-upload text-center py-1"
                          style="height: auto !important"
                        >
                          <img
                            :src="apiURL + '/' + about.data_values[0].image"
                            class="img img-thumbails rounded img-fluid"
                          />
                          <a
                            class="text-danger mt-2 w-100"
                            href="javascript:void(0)"
                            @click.prevent="showFilePicker('cover-image')"
                          >
                            <i class="ri-upload-cloud-line"></i>
                            Change image</a
                          >
                        </div>
                      </div>

                      <div
                        class="jb-gallery-upload"
                        style="height: 20.5rem"
                        v-else
                        @click="showFilePicker('cover-image')"
                      >
                        <p class="text-center">
                          <img
                            :src="`${publicPath}img/placeholder-small.png`"
                            style="width: 80px"
                            class="img img-fluid"
                          />
                        </p>
                        <p class="text-mutted text-center">
                          Click to add image
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7">
                  <section class="py-5">
                    <h5>Why Choose Us Sections</h5>
                    <article
                      v-for="(article, i) in about.data_values[0].whyChooseUs
                        .sections"
                      :key="'article' + i"
                      class="py-4"
                    >
                      <div class="form-group">
                        <label for="">Title</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="title"
                          v-model="
                            about.data_values[0].whyChooseUs.sections[i].title
                          "
                        />
                      </div>
                      <div class="form-group">
                        <label for="">Description</label>
                        <textarea
                          class="form-control"
                          placeholder="Summary"
                          v-model="
                            about.data_values[0].whyChooseUs.sections[i]
                              .description
                          "
                        ></textarea>
                      </div>
                      <div class="d-flex align-items-center">
                        <button
                          type="button"
                          class="btn btn-sm btn-danger"
                          @click="removeSection(i)"
                          v-if="i > 0"
                        >
                          Remove
                        </button>
                        <button
                          type="button"
                          class="btn btn-sm btn-dark ml-auto"
                          v-if="
                            i ==
                            about.data_values[0].whyChooseUs.sections.length - 1
                          "
                          @click="addSection"
                        >
                          Add New
                        </button>
                      </div>
                    </article>
                  </section>
                </div>
                <hr />
                <div class="col-2">
                  <wolf-button
                    caption="Save Changes"
                    @btn-role="handleSubmit"
                    :disabler="!about.data_values[0].whoWeAre"
                    activator="saving_info"
                  ></wolf-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  data: () => ({
    about: {
      data_keys: "about",
      data_values: [
        {
          sloga: null,
          whoWeAre: null,
          strategy: null,
          mission: null,
          vision: null,
          image: null,
          whyChooseUs: {
            sections: [
              {
                title: null,
                description: null,
              },
            ],
          },
          objectives: null,
        },
      ],
    },
  }),
  created() {
    this.$store
      .dispatch("getRequest", `admin/frontends/list/about`)
      .then((response) => {
        if (response.data.row.data_values) {
          this.about.data_values = response.data.row.data_values;
        }
      });
  },
  methods: {
    addSection() {
      this.about.data_values[0].whyChooseUs.sections.push({
        title: null,
        description: null,
      });
    },
    removeSection(i) {
      this.about.data_values[0].whyChooseUs.sections.splice(i, 1);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.about.data_values.objectives) {
            this.about.data_values.objectives =
              this.about.data_values.objectives.split("|");
          }
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.about),
              url: "admin/frontends/create",
            })
            .then((response) => {
              if (response.data.status) {
              }
            });
        }
      });
    },
    handleUploadedImage(file) {
      this.about.data_values[0].image = file.file_path;
    },
  },
};
</script>
