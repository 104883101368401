<template>
  <div class="container-fluid">
    <off-canvas
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
    >
      <span slot="head">
        {{ newBanner.id ? "Update Banner" : "Add a new Banner" }}
      </span>
      <div slot="body">
        <form class="forms-sample">
          <div class="form-group">
            <label>Banner Group</label>
            <input
              type="text"
              class="form-control"
              readonly
              value="Hero Banner"
            />
          </div>
          <div class="form-group">
            <label>Title</label>
            <input
              type="text"
              class="form-control"
              placeholder="Banner title"
              v-model="newBanner.title"
            />
          </div>
          <div class="form-group">
            <div class="d-flex align-items-center">
              <div class="w-100">
                <label class="form-label">Banner Image</label>
                <files-uploader
                  :input-id="'cover-image'"
                  @after-uploading="handleUploadedImage"
                ></files-uploader>
                <div v-if="newBanner.banner_image">
                  <div
                    class="jb-gallery-upload text-center py-1"
                    style="height: auto !important"
                  >
                    <img
                      :src="apiURL + '/' + newBanner.banner_image"
                      class="img img-thumbails rounded img-fluid"
                    />
                    <a
                      class="text-danger mt-2 w-100"
                      href="javascript:void(0)"
                      @click.prevent="showFilePicker('cover-image')"
                    >
                      <i class="ri-upload-cloud-line"></i>
                      Change image</a
                    >
                  </div>
                </div>

                <div
                  class="jb-gallery-upload"
                  style="height: 8.5rem"
                  v-else
                  @click="showFilePicker('cover-image')"
                >
                  <p class="text-center">
                    <img
                      :src="`${publicPath}img/placeholder-small.png`"
                      style="width: 80px"
                      class="img img-fluid"
                    />
                  </p>
                  <p class="text-mutted text-center">Click to add image</p>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3 pb-4">
            <div class="d-flex w-50">
              <wolf-button
                caption="Confirm and Save"
                @btn-role="handleBannerAdd"
                :disabler="!newBanner.title || !newBanner.banner_image"
                activator="saving_user"
              ></wolf-button>
            </div>
          </div>
        </form>
      </div>
    </off-canvas>
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">
                Banner and sliding images
              </h3>
              <div class="nk-block-des text-soft">
                <p>You have total {{ banners.length }} banners.</p>
              </div>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a
                  href="javascript:void(0)"
                  class="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="more-options"
                  ><em class="icon ni ni-more-v"></em
                ></a>
                <div class="toggle-expand-content" data-content="more-options">
                  <ul class="nk-block-tools g-3">
                    <li>
                      <div class="form-control-wrap">
                        <div class="form-icon form-icon-right">
                          <em class="icon ni ni-search"></em>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          id="default-04"
                          placeholder="Search by name"
                        />
                      </div>
                    </li>
                    <li>
                      <div class="dropdown">
                        <a
                          href="javascript:void(0)"
                          class="
                            dropdown-toggle dropdown-indicator
                            btn btn-outline-light btn-white
                          "
                          data-toggle="dropdown"
                          >Status</a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <ul class="link-list-opt no-bdr">
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Actived</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><span>Disabled</span></a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li class="nk-block-tools-opt">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="btn btn-icon btn-primary d-md-none"
                        ><em class="icon ni ni-plus"></em></a
                      ><a
                        href="javascript:void(0)"
                        @click.prevent="toggleModal"
                        class="btn btn-primary d-none d-md-inline-flex"
                        ><em class="icon ni ni-plus"></em><span>Add</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="nk-tb-list is-separate mb-3">
            <div class="nk-tb-item nk-tb-head">
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid"
                  /><label class="custom-control-label" for="uid"></label>
                </div>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="sub-text">Image</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Group</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Title</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Width</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span class="sub-text">Height</span>
              </div>
              <div class="nk-tb-col tb-col-md">
                <span class="sub-text">Status</span>
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1 my-n1">
                  <li>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger mr-n1"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-na"></em
                              ><span>Disable Selected</span></a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0)"
                              ><em class="icon ni ni-trash"></em
                              ><span>Remove Seleted</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="nk-tb-item"
              v-for="(banner, i) in banners"
              :key="'banner' + i"
            >
              <div class="nk-tb-col nk-tb-col-check">
                <div
                  class="
                    custom-control custom-control-sm custom-checkbox
                    notext
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="uid1"
                  /><label class="custom-control-label" for="uid1"></label>
                </div>
              </div>
              <div class="nk-tb-col">
                <span class="tb-product"
                  ><img
                    :src="apiURL + '/' + banner.banner_image"
                    class="thumb"
                  />
                </span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span>{{ banner.banner_group || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-lg">
                <span>{{ banner.title || "N/A" }}</span>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="tb-amount">{{
                  addComma(banner.banner_width) || "N/A"
                }}</span>
              </div>
              <div class="nk-tb-col tb-col-mb">
                <span class="tb-amount">{{
                  addComma(banner.banner_height) || "N/A"
                }}</span>
              </div>

              <div class="nk-tb-col tb-col-md">
                <span class="tb-status text-success" v-if="banner.status == 1"
                  >Active</span
                >
                <span class="tb-status text-danger" v-if="banner.status == 0"
                  >Disabled</span
                >
              </div>
              <div class="nk-tb-col nk-tb-col-tools">
                <ul class="nk-tb-actions gx-1">
                  <li>
                    <div class="dropdown">
                      <a
                        href="javascript:void(0)"
                        class="dropdown-toggle btn btn-icon btn-trigger"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        ><em class="icon ni ni-more-h"></em
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right" style="">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="selectBanner(banner)"
                              ><em class="icon ni ni-pen"></em
                              ><span>Edit</span></a
                            >
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click.prevent="
                                handleDeleteAlertModal({
                                  tableName: 'banners',
                                  entityIdentifer: 'Banner',
                                  entity: `${banner.title}`,
                                  url: `admin/frontend-images/delete/${banner.id}`,
                                })
                              "
                              ><em class="icon ni ni-trash"></em
                              ><span>Delete</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import WolfFilesUploader from "@/components/WolfFilesUploader.vue";
export default {
  name: "Banner",
  mixins: [dashboardServices],
  components: {
    "files-uploader": WolfFilesUploader,
  },
  data: () => ({
    banners: [],
    newBanner: {
      banner_group: null,
      banner_height: null,
      banner_width: null,
      button_caption: null,
      title: null,
      subtitle: null,
      url: null,
    },
  }),
  beforeCreate() {
    this.$store
      .dispatch("getRequest", "admin/frontend-images/list")
      .then((response) => {
        this.banners = response.data.banners;
      });
  },
  methods: {
    handleUploadedImage(file) {
      this.newBanner.banner_image = file.file_path;
    },
    handleBannerAdd() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.emailError = false;
          this.phoneError = false;
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.newBanner),
              url: "admin/frontend-images/create",
            })
            .then((response) => {
              if (response.data.status) {
                this.successMessage = true;
                this.$validator.reset();
                this.toggleModal();
                if (this.newBanner.id) location.reload();
                else this.banners = [...response.data.banners, ...this.banners];
                this.clearObject(this.newBanner);
              }
            });
        }
      });
    },
    selectBanner(banner) {
      this.newBanner = {
        ...banner,
      };
      this.toggleModal();
    },
    editBannerImage(banner) {
      this.handleEntityImageModify({
        record_id: banner.id,
        url: `admin/frontend-images/image/update`,
      });
    },
  },
};
</script>
