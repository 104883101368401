<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Site Setting</h3>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <h3>General Info</h3>
                </div>
                <div class="col-lg-5 mt-2">
                  <div class="d-flex align-items-center">
                    <div class="w-100">
                      <label class="form-label">Working hours Image</label>
                      <hview-upload
                        :input-id="'cover-image'"
                        @after-uploading="handleUploadedImage"
                      ></hview-upload>
                      <div v-if="general.data_values[0].working_hours">
                        <div
                          class="jb-gallery-upload text-center py-1"
                          style="height: auto !important"
                        >
                          <img
                            :src="apiURL + '/' + general.data_values[0].working_hours"
                            class="img img-thumbails rounded img-fluid"
                          />
                          <a
                            class="text-danger mt-2 w-100"
                            href="javascript:void(0)"
                            @click.prevent="showFilePicker('cover-image')"
                          >
                            <i class="ri-upload-cloud-line"></i>
                            Change image</a
                          >
                        </div>
                      </div>

                      <div
                        class="jb-gallery-upload"
                        style="height: 25.8rem"
                        v-else
                        @click="showFilePicker('cover-image')"
                      >
                        <p class="text-center">
                          <img
                            :src="`${publicPath}img/placeholder-small.png`"
                            style="width: 80px"
                            class="img img-fluid"
                          />
                        </p>
                        <p class="text-mutted text-center">
                          Click to add image
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 mt-2">
                  <div class="form-group">
                    <label for="">Footer Info</label>
                    <textarea
                      class="form-control"
                      placeholder="Footer Info"
                      v-model="general.data_values[0].footerInfo"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label for="">Phone Number</label>
                    <input
                      type="tel"
                      class="form-control"
                      placeholder="Phone Number"
                      v-model="general.data_values[0].social.phone"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Email Address</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email Address"
                      v-model="general.data_values[0].social.email"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Address Line"
                      v-model="general.data_values[0].social.address"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row g-3">
                <div class="col-12">
                  <h3>Social Networks</h3>
                  <div class="form-group">
                    <label for="">Facebook</label>
                    <input
                      type="url"
                      class="form-control"
                      placeholder="Facebook URL"
                      v-model="general.data_values[0].social.facebook"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Twitter</label>
                    <input
                      type="url"
                      class="form-control"
                      placeholder="Twitter URL"
                      v-model="general.data_values[0].social.twitter"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">Instagram</label>
                    <input
                      type="url"
                      class="form-control"
                      placeholder="Instagram URL"
                      v-model="general.data_values[0].social.instagram"
                    />
                  </div>
                  <div class="form-group">
                    <label for="">LinkedIn</label>
                    <input
                      type="url"
                      class="form-control"
                      placeholder="LinkedIn URL"
                      v-model="general.data_values[0].social.linkedin"
                    />
                  </div>
                </div>
                <hr />

                <div class="col-2">
                  <wolf-button
                    caption="Save Changes"
                    @btn-role="handleSubmit"
                    :disabler="!general.data_values[0].footerInfo"
                    activator="saving_info"
                  ></wolf-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "General",
  data: () => ({
    general: {
      data_keys: "general_info",
      data_values: [
        {
          working_hours:null,
          footerInfo: null,
          social: {
            facebook: null,
            twitter: null,
            instagram: null,
            linkedin: null,
          },
        },
      ],
    },
  }),
  created() {
    this.$store
      .dispatch("getRequest", `admin/frontends/list/general_info`)
      .then((response) => {
        if (response.data.row.data_values) {
          this.general.data_values = response.data.row.data_values;
        }
      });
  },
  methods: {
    handleUploadedImage(file) {
      this.general.data_values[0].working_hours = file.file_path;
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.general),
              url: "admin/frontends/create",
            })
            .then((response) => {
              if (response.data.status) {
              }
            });
        }
      });
    },
  },
};
</script>
