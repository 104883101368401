<template>
  <section
    :style="`background-image:url(${publicPath}img/universe-bg.jpg);background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`"
  >
    <div class="nk-block nk-block-middle nk-auth-body wide-xs">
      <div class="card mt-5">
        <div class="card-inner card-inner-lg">
          <div class="nk-block-head">
            <div class="brand-logo pb-4 text-left">
              <router-link :to="{ name: '' }" class="logo-link"
                ><img
                  class="logo-dark logo-img logo-img-lg"
                  :src="`${publicPath}img/logo.png`"
                  alt="logo"
              /></router-link>
            </div>
            <div class="nk-block-head-content">
              <h4 class="nk-block-title">Login</h4>
              <div class="nk-block-des">
                <!-- <p>Access the Admin panel using your email and passcode.</p> -->
              </div>
            </div>
          </div>
          <form action="" method="POST" @submit.prevent="authenticateUser">
            <div class="form-group">
              <div class="form-label-group">
                <label class="form-label" for="default-01"
                  >Email</label
                >
              </div>
              <div class="form-control-wrap">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="default-01"
                  placeholder="Enter your email address"
                  name="Username"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('Username') }"
                  v-model="credentials.email"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="form-label-group">
                <label class="form-label" for="password">Password</label
                ><router-link
                  class="link link-primary link-sm"
                  :to="{ name: '' }"
                  >Forgot Password?</router-link
                >
              </div>
              <div class="form-control-wrap">
                <a
                  href="javascript:void(0)"
                  class="form-icon form-icon-right passcode-switch lg"
                  data-target="password"
                  ><em class="passcode-icon icon-show icon ni ni-eye"></em
                  ><em
                    class="passcode-icon icon-hide icon ni ni-eye-off"
                  ></em></a
                ><input
                  type="password"
                  class="form-control form-control-lg"
                  id="password"
                  placeholder="Enter your passcode"
                  name="password"
                  :class="{ 'is-invalid': errors.has('password') }"
                  v-validate="'required'"
                  v-model="credentials.password"
                />
              </div>
            </div>
            <div class="alert alert-danger my-2" v-if="invalidLogin">
              <span>Invalid password or Email addess</span>
            </div>
            <div
              class="alert alert-success fade show my-2"
              role="alert"
              v-if="loginSucceed"
            >
              <strong>Success!</strong> Login Success. Redirecting...
            </div>
            <div class="form-group">
              <wolf-button
                caption="Sign In"
                button-type="submit"
                @btn-role="authenticateUser"
                :disabler="!credentials.email || !credentials.password"
                activator="signing"
                >Sign in</wolf-button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      credentials: {
        email: null,
        password: null,
      },
      invalidLogin: false,
      loginSucceed: false,
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      window.location.replace("/admin/dashboard");
    }
  },
  methods: {
    authenticateUser: function () {
      this.$store
        .dispatch("authRequest", {
          formData: this.formData(this.credentials),
          url: "auth/login",
        })
        .then((response) => {
          if (response.data.status) {
            this.invalidLogin = false;
            this.loginSucceed = true;
            location.replace("/admin/dashboard");
          }
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status == 401) {
            this.invalidLogin = true;
            this.loginSucceed = false;
          }
        });
    },
  },
};
</script>
