<template>
  <nav aria-label="Page navigation" v-if="totalPages > 0">
    <ul class="pagination justify-content-center mb-0">
      <li class="page-item mr-auto">
        <div class="d-flex">
          <a
            href="javascript:void(0)"
            @click="updatePage(0)"
            class="page-link first-link"
          >
            <div class="first-icon"></div>
          </a>
          <a
            class="page-link d-flex"
            @click="updatePage(prev)"
            href="javascript:void(0)"
          >
            <div class="previous-icon"></div>
            <div class="link-text ml-2">Previous</div>
            <div></div>
          </a>
        </div>
      </li>
      <!-- <li class="page-item" v-if="firstPage">
        <a class="page-link" @click="updatePage(0)" href="javascript:void(0)"
          >1</a
        >
      </li> -->
      <li
        class="page-item page-link text-dark px-3"
        :class="dotsIcon"
        v-if="firstPage"
      ></li>
      <li
        class="page-item"
        :class="{ active: current_page == page }"
        v-for="(page, index) in pages"
        :key="index"
      >
        <a
          class="page-link"
          @click="updatePage(page)"
          href="javascript:void(0)"
          >{{ page + 1 }}</a
        >
      </li>
      <li
        class="page-item page-link text-dark px-3"
        :class="dotsIcon"
        v-if="lastPage"
      ></li>
      <!-- <li class="page-item" v-if="lastPage">
        <a
          class="page-link"
          @click="updatePage(totalPages - 1)"
          href="javascript:void(0)"
          >{{ totalPages }}</a
        >
      </li> -->
      <li class="page-item ml-auto">
        <div class="d-flex">
          <a
            class="page-link d-flex"
            href="javascript:void(0)"
            aria-label="Next"
            @click="updatePage(next)"
          >
            <div class="link-text mr-2">Next</div>
            <div class="next-icon"></div>
          </a>
          <a
            href="javascript:void(0)"
            class="page-link last-link"
            @click="updatePage(totalPages - 1)"
          >
            <div class="last-icon"></div>
          </a>
        </div>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "MyPaginations",
  props: {
    current_page: Number,
    row_count_page: Number,
    total_records: Number,
    page_range: Number,
    dotsIcon: {
      type: String,
      default: "ti-more"
    }
  },
  computed: {
    prev() {
      return this.current_page - 1;
    },
    next() {
      return this.current_page + 1;
    },
    rangeStart() {
      var start = this.current_page - this.page_range;
      return start > 0 ? start : 0;
    },
    rangeEnd() {
      var end = this.current_page + this.page_range;
      return end < this.totalPages ? end : this.totalPages;
    },
    pages() {
      var pages = [];
      for (var i = this.rangeStart; i < this.rangeEnd; i++) {
        pages.push(i);
      }
      return pages;
    },
    totalPages() {
      return Math.ceil(this.total_records / this.row_count_page);
    },
    firstPage() {
      return this.rangeStart !== 0;
    },
    lastPage() {
      return this.rangeEnd < this.totalPages;
    },
    showPrevLink() {
      return this.current_page == 0 ? false : true;
    },
    showNextLink() {
      return this.current_page == this.totalPages - 1 ? false : true;
    }
  },
  methods: {
    updatePage(pageNumber) {
      this.$emit("page-update", pageNumber);
    }
  }
};
</script>
<style lang="css">
.page-link .next-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0NCI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBkPSJNMTggMzFsOC41LTguNUwxOCAxNCIvPjwvc3ZnPg==)
    no-repeat;
}
.page-link .next-icon.disabled-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0NCI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBkPSJNMTggMzFsOC41LTguNUwxOCAxNCIvPjwvc3ZnPg==)
    no-repeat;
}
.page-link .previous-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0NCI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBkPSJNMjYuNSAxNEwxOCAyMi41bDguNSA4LjUiLz48L3N2Zz4=)
    no-repeat;
}
.page-link .previous-icon.disabled-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0NCI+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBkPSJNMjYuNSAxNEwxOCAyMi41bDguNSA4LjUiLz48L3N2Zz4=)
    no-repeat;
}
.page-link .next-icon,
.page-link .previous-icon,
.page-item .last-link .last-icon,
.page-item .first-link .first-icon {
  background-position: 50%;
  height: 16px;
  width: 16px;
}
.page-link .previous-icon.disabled-icon,
.page-link .next-icon.disabled-icon,
.page-item .last-link .last-icon.disabled-icon,
.page-item .first-link .first-icon.disabled-icon {
  opacity: 0.2;
  cursor: default;
}
.page-item .last-link .last-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0IDE2VjEuMDAxaDFWMTZoLTF6TTIgMS43MjJMMi43MDIgMSAxMCA4LjUgMi43MDIgMTYgMiAxNS4yNzggOC41OTYgOC41IDIgMS43MjJ6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=)
    no-repeat;
}
.page-item .last-link .last-icon.disabled-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0IDE2VjEuMDAxaDFWMTZoLTF6TTIgMS43MjJMMi43MDIgMSAxMCA4LjUgMi43MDIgMTYgMiAxNS4yNzggOC41OTYgOC41IDIgMS43MjJ6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=)
    no-repeat;
}

.page-item .first-link .first-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjI5NyAxNkw2IDguNSAxMy4yOTcgMWwuNzAzLjcyMkw3LjQwMyA4LjUgMTQgMTUuMjc4bC0uNzAzLjcyMnpNMSAxNlYxaDF2MTVIMXoiLz48L3N2Zz4=)
    no-repeat;
}
.page-item .first-link .first-icon.disabled-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjI5NyAxNkw2IDguNSAxMy4yOTcgMWwuNzAzLjcyMkw3LjQwMyA4LjUgMTQgMTUuMjc4bC0uNzAzLjcyMnpNMSAxNlYxaDF2MTVIMXoiLz48L3N2Zz4=)
    no-repeat;
}
.link-text {
  margin-top: -2px;
}
.page-link:hover {
  background-color: transparent;
  border-color: transparent;
}
</style>
