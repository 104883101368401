<template>
  <div class="nk-content-inner">
    <div class="nk-content-body">
      <h4>Customers Request (RFQ)</h4>
      <hr />
      <div class="nk-msg nk-msg-boxed" v-if="requests.length">
        <div class="nk-msg-aside" style="width: 420px">
          <div class="nk-msg-nav">
            <ul class="nk-msg-menu">
              <li class="nk-msg-menu-item active"><a href="">Requests</a></li>
              <li class="nk-msg-menu-item ml-auto">
                <a
                  href=""
                  class="search-toggle toggle- invisible"
                  data-target="search"
                  ><em class="icon ni ni-search"></em
                ></a>
              </li>
            </ul>
            <div class="search-wrap invisible" data-search="search">
              <div class="search-content">
                <a
                  href="javascript:void(0)"
                  class="search-back btn btn-icon toggle-search"
                  data-target="search"
                  ><em class="icon ni ni-arrow-left"></em></a
                ><input
                  type="text"
                  class="form-control border-transparent form-focus-none"
                  placeholder="Search by user or message"
                /><button class="search-submit btn btn-icon">
                  <em class="icon ni ni-search"></em>
                </button>
              </div>
            </div>
          </div>
          <div class="nk-msg-list" data-simplebar="init">
            <div class="simplebar-wrapper" style="margin: 0px">
              <div class="simplebar-mask">
                <div class="simplebar-offset" style="right: 0px; bottom: 0px">
                  <div
                    class="simplebar-content-wrapper"
                    style="height: 100%; overflow: scroll"
                  >
                    <div class="simplebar-content" style="padding: 0px">
                      <div
                        class="nk-msg-item"
                        v-for="(request, i) in requests"
                        :key="'request' + i"
                        :class="{ current: selectedRequest.id == request.id }"
                        @click="selectedRequest = request"
                      >
                        <div class="nk-msg-media user-avatar">
                          <span>{{ customAvatar(request.name) }}</span>
                        </div>
                        <div class="nk-msg-info">
                          <div class="nk-msg-from">
                            <div class="nk-msg-sender">
                              <div class="name">{{ request.name }}</div>
                            </div>
                            <div class="nk-msg-meta">
                              <div class="date">
                                {{ chatMiniDate(request.created_at) }}
                              </div>
                            </div>
                          </div>
                          <div class="nk-msg-context">
                            <div class="nk-msg-text">
                              <h6 class="title">
                                Service: {{ request.service }}
                              </h6>
                              <p class="two-line">
                                {{ request.message }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="simplebar-track simplebar-horizontal"
              style="visibility: hidden"
            >
              <div
                class="simplebar-scrollbar simplebar-visible"
                style="width: 0px; display: none"
              ></div>
            </div>
            <div
              class="simplebar-track simplebar-vertical"
              style="visibility: hidden"
            >
              <div
                class="simplebar-scrollbar simplebar-visible"
                style="
                  height: 0px;
                  transform: translate3d(0px, 0px, 0px);
                  display: none;
                "
              ></div>
            </div>
          </div>
        </div>
        <div class="nk-msg-body bg-white">
          <div class="nk-msg-reply nk-reply" data-simplebar="init">
            <div class="simplebar-wrapper" style="margin: 0px">
              <div class="simplebar-mask">
                <div class="simplebar-offset" style="right: 0px; bottom: 0px">
                  <div
                    class="simplebar-content-wrapper"
                    style="height: 100%; overflow: scroll"
                  >
                    <div
                      class="simplebar-content"
                      :class="{ invisible: empty(selectedRequest) }"
                      style="padding: 0px"
                    >
                      <div class="nk-reply-item">
                        <div class="nk-reply-header">
                          <div class="user-card">
                            <div class="user-avatar sm bg-blue">
                              <span>{{
                                customAvatar(selectedRequest.name)
                              }}</span>
                            </div>
                            <div class="user-name">
                              {{ selectedRequest.name }}
                            </div>
                          </div>
                          <div class="date-time">
                            {{ chatDate(selectedRequest.created_at) }}
                          </div>
                        </div>
                        <div class="nk-reply-body">
                          <div class="nk-reply-entry entry">
                            <p>
                              {{ selectedRequest.message }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Requests",
  data: () => ({
    requests: [],
    selectedRequest: {},
  }),
  beforeCreate() {
    this.$store
      .dispatch("getRequest", "admin/requests/list")
      .then((response) => {
        this.requests = response.data.requests;
      });
  },
  methods: {
    customAvatar(name) {
      var results = null;
      if (typeof name != "undefined") {
        var _name = name.split(" ");
        var firstname = _name[0];
        results = firstname[0];
        if (_name[1]) {
          var lastname = _name[1];
          results += lastname[0];
        }
        return results;
      }
    },
  },
};
</script>
