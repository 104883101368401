export const dashboardServices = {
  data: () => ({}),
  methods: {
    computeUserAvatar(user) {
      var firstname = user.first_name[0];
      var lastname = user.last_name ? user.last_name[0] : "";
      return `${firstname} ${lastname}`;
    },
    /*getDateDifference(date, otherDate) {
      const diffDays = (date, otherDate) =>
        Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
      return diffDays;
    }, */
  },
  beforeUpdate() {
    [...document.querySelectorAll("div.dropdown-menu")].forEach((elt) => {
      elt.classList.remove("show");
    });
  },
  updated() {
    [
      ...this.$el.querySelectorAll(".dropdown a[data-toggle='dropdown']"),
    ].forEach((item) => {
      item.addEventListener("click", () => {
        [...document.querySelectorAll("div.dropdown-menu")].forEach((elt) => {
          elt.classList.remove("show");
        });
        item.parentElement.classList.toggle("show");
        item.parentElement
          .querySelector("div.dropdown-menu")
          .classList.toggle("show");
      });
    });
  },
};
